<template>
  <div>
    Pressed Press is founded in 2017, currently has
    <a href="mailto:cz@pressed.press" style="color:red;">Chen Zhou</a>,
    <a href="mailto:ts@pressed.press" style="color:blue;">tianshi</a>, and
    <a href="mailto:t3_jin@yahoo.co.jp" style="color:white;">KiZ</a> as its
    member. We use medias including photography, music, publication to perform
    our focus on seeing contemporary socio-cultural scenes through the
    perspective of everyday life, and re-constructing the neglected history.
    <br /><br />
    Contact us: <a href="mailto:press@pressed.press">press@pressed.press</a
    ><br />
    Instagram: <a href="https://instagram.com/pressedpress">pressedpress</a>
  </div>
</template>
<script>
export default {
  name: "EnAbout"
};
</script>
<style scoped lang="scss">
a {
  color: #008d45;
}
</style>
