<template>
  <div>
    扁社目前由<a href="mailto:cz@pressed.press" style="color:red;">周琛</a>、<a
      href="mailto:ts@pressed.press"
      style="color:blue;"
      >甜食</a
    >和
    <a href="mailto:t3_jin@yahoo.co.jp" style="color:white;">KiZ</a>
    组成，成立于 2017
    年。从事摄影、音乐、独立出版等领域的实践。我们试图从日常生活直视当代文化，重建无关紧要的历史。
    <br /><br />
    联系我们：<a href="mailto:press@pressed.press">press@pressed.press</a><br />
    Instagram：<a href="https://instagram.com/pressedpress">pressedpress</a>
  </div>
</template>
<script>
export default {
  name: "About"
};
</script>
<style scoped></style>
