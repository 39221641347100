<template>
  <div>
    <img src="@/imgs/pp01.png" style="width:100%;" /><br />
    This book is intended to be a physical copy of CAI Xiao's photography series
    <i>Landscapes With Color</i>, made under the principle of “keeping the cost
    as low as possible”, while its origin exists as
    <a href="https://cxcxcx.cx">digital files</a>. However, when these
    landscapes with color is finally presented in this form of monochrome, or
    “colorless”, it somehow diverged from the original.
    <p>
      <br />
      <i
        >“I once saw an interview of a senior Chinese photographer on a
        megazine, while mentioning William Eggleston, this photographer wanted
        color to be part of their photography language, not only take
        photographs with color.</i
      >
    </p>
    <p>
      <br />
      <i
        >「“I recalled this interview when I was considering giving a name to
        this series of landscape photographs, and realized, again, that for me,
        it's completely opposite: I just want my photographs have color. Thus it
        became <i>Landscapes With Color.</i>
      </i>
    </p>
    <p></p>
    <br />
    <p class="purchase">
      Order: <a href="https://k.ruyu.com/ZPsbLGQ8">Weidian</a> (currently sold
      out, on back order)
    </p>
    <br />
    Contact of author:<br />
    Weibo: <a href="https://weibo.com/516653086">@蔡驍</a><br />
    Email: <a href="mailto:kxxhmr@gmail.com">kxxhmr@gmail.com</a><br />
    Instagram: <a href="https://instagram.com/s_aigyou">s_aigyou</a><br /><br />

    ----------<br />

    49 pages<br />
    Horizon 80g multi-use copy paper, tracing paper<br />
    Hand numbered and signed, hand written book title<br />
    Xerox copied<br />
    Binded by MAX paper clipper<br />
    First printed on 4th January, 2020
    <p></p>
    <img src="@/imgs/cxc.jpg" style="width:100%;" /><br />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@baebcf4/imgs/cxpreview.gif"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx2.JPG"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx3.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx4.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx5.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx6.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx7.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx8.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx9.jpg"
      style="width:100%;"
    />
  </div>
</template>
<script>
export default {
  name: "EnBook1"
};
</script>
<style scoped lang="scss">
a {
  color: #e41722;
}
.purchase {
  border: 1px;
  border-style: solid;
  border-color: #000000;
}
</style>
