<template>
  <div>
    <img src="@/imgs/book2/main.jpg" style="width:100%;" /><br />
	This publication contains three zines made for the bar Яów. They used same production process as CAI Xiao's <i>Landscapes With Color</i>. The three zines are: <br /><br />

	chimaybe, <i>JINLING MURDERER</i><br />
	CAI Xiao, <i>Landscapes With Strange Color</i><br />
	furfur, <i>PATH</i><br />
    <br />
    <p class="purchase">
      Order: contact <a href="https://m.dianping.com/shopshare/G9RQcpk66G1u1nBF">Яów 热凹</a>
    </p>
    <br />
	Contact of authors：<br /><br />
	chimaybe<br />
	Instagram: <a href="https://instagram.com/chimaybe">chimaybe</a><br /><br />

	CAI Xiao<br />
    Weibo: <a href="https://weibo.com/516653086">@蔡驍</a><br />
    Email: <a href="mailto:kxxhmr@gmail.com">kxxhmr@gmail.com</a><br />
    Instagram：<a href="https://instagram.com/s_aigyou">s_aigyou</a><br /><br />

	furfur<br />
	Wechat official account: 无情的⻛<br /><br />

    ----------<br />

    31 (c)/35 (C)/27 (f) pages<br />
    Horizon 80g multi-use copy paper, tracing paper<br />
    Hand numbered and signed, hand written book title<br />
    Xerox copied<br />
	Binded by MAX paper clipper<br />
    First printed on May, 2020
    <p></p>
	<video width="100%" controls poster="@/imgs/book2/3zinesvlog-0001.jpeg">
	<source src="http://dpv.videocc.net/3c94696c86/9/3c94696c86f49b4b04a8733d0307d8b9_2.mp4" type="video/mp4">
	Your browser does not support the video tag.
	</video>
	↑ furfur exhibited this zine series in her project <i>Mingfa jigsaw</i>.
    <img src="@/imgs/book2/chimaybe.jpg" style="width:100%;" /><br />
    <img src="@/imgs/book2/cx.jpg" style="width:100%;" /><br />
    <img src="@/imgs/book2/mao.jpg" style="width:100%;" /><br />
  </div>
</template>
<script>
export default {
  name: "Book2"
};
</script>
<style scoped lang="scss">
a {
  color: #e41722;
}
.purchase {
  border: 1px;
  border-style: solid;
  border-color: #000000;
}
</style>
