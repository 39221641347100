<template>
  <div>
    <img src="@/imgs/pp01.png" style="width:100%;" /><br />
    这本画册最初是作为蔡驍《有颜色的风景》系列彩色照片的<a
      href="https://cxcxcx.cx"
      >数字原稿</a
    >的实物副本，但最终当这些「有颜色的风景」被以一种黑白的、没有颜色的方式呈现出来时，它却与原作分离开了。这是以「最低成本的实现方式」的原则所制作的摄影画册。
    <p>
      <br />
      <i
        >「若干年前，在某個雜誌上看到一篇某國內前輩攝影家的訪談，訪談中，這位攝影家在提到艾格斯頓時說希望讓顏色成為自己照片的一種語言，而不只是有顏色的照片。</i
      >
    </p>
    <p>
      <br />
      <i
        >「在考慮給幾年前這些風景照取標題時回想起那篇訪談，再一次確認自己似乎是反過來的：只是希望讓這些照片帶有顏色。於是就成了『有顏色的風景』。」</i
      >
    </p>
    <p></p>
    <br />
    <p class="purchase">
      购买：<a href="https://k.ruyu.com/ZPsbLGQ8">微店</a>（第一批售罄，可预订）
    </p>
    <br />
    作者联系方式：<br />
    微博：<a href="https://weibo.com/516653086">@蔡驍</a><br />
    邮箱：<a href="mailto:kxxhmr@gmail.com">kxxhmr@gmail.com</a><br />
    Instagram：<a href="https://instagram.com/s_aigyou">s_aigyou</a><br /><br />

    ----------<br />

    49 页<br />
    好顺 Horizon 80g 多功能复印纸、硫酸纸<br />
    手写签名、封面，内页复印机印刷<br />
    MAX 推夹器装订<br />
    2020 年 1 月 4 日第一次印刷
    <p></p>
    <img src="@/imgs/cxc.jpg" style="width:100%;" /><br />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@baebcf4/imgs/cxpreview.gif"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx2.JPG"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx3.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx4.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx5.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx6.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx7.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx8.jpg"
      style="width:100%;"
    />
    <img
      src="https://cdn.jsdelivr.net/gh/lamons/PP_website@856287e/imgs/cx9.jpg"
      style="width:100%;"
    />
  </div>
</template>
<script>
export default {
  name: "Book1"
};
</script>
<style scoped lang="scss">
a {
  color: #e41722;
}
.purchase {
  border: 1px;
  border-style: solid;
  border-color: #000000;
}
</style>
