<template>
  <div>
    <img src="@/imgs/book2/main.jpg" style="width:100%;" /><br />
	本项目为受酒吧 Яów 委托制作，在实地售卖的总共三册的 zine 套装。在制作工艺上，采取了和蔡驍《有颜色的风景》相同的印刷和装帧。三册 zine 分别为：<br /><br />
	chimaybe《離得越緊她越是害怕》<br />
	蔡驍《颜色奇怪的风景》<br />
	毛利牙《径》<br />
    <br />
    <p class="purchase">
      购买：请洽 <a href="https://m.dianping.com/shopshare/G9RQcpk66G1u1nBF">Яów 热凹</a>
    </p>
    <br />
	作者联系方式：<br /><br />
	chimaybe<br />
	Instagram: <a href="https://instagram.com/chimaybe">chimaybe</a><br /><br />

蔡驍<br />
    微博：<a href="https://weibo.com/516653086">@蔡驍</a><br />
    邮箱：<a href="mailto:kxxhmr@gmail.com">kxxhmr@gmail.com</a><br />
    Instagram：<a href="https://instagram.com/s_aigyou">s_aigyou</a><br /><br />

	毛利牙<br />
	微信公众号: 无情的⻛<br /><br />

    ----------<br />

    31 (c)/35 (毛)/27 (蔡) 页<br />
    好顺 Horizon 80g 多功能复印纸、硫酸纸<br />
    手写签名、封面，内页复印机印刷<br />
    MAX 推夹器装订<br />
    2020 年 5 月第一次印刷
    <p></p>
	<video width="100%" controls poster="@/imgs/book2/3zinesvlog-0001.jpeg">
	<source src="http://dpv.videocc.net/3c94696c86/9/3c94696c86f49b4b04a8733d0307d8b9_2.mp4" type="video/mp4">
	Your browser does not support the video tag.
	</video>
	↑ 作者毛利牙在她的「明发拼图摄影展」中展示张贴了本作品。
    <img src="@/imgs/book2/chimaybe.jpg" style="width:100%;" /><br />
    <img src="@/imgs/book2/cx.jpg" style="width:100%;" /><br />
    <img src="@/imgs/book2/mao.jpg" style="width:100%;" /><br />
  </div>
</template>
<script>
export default {
  name: "Book2"
};
</script>
<style scoped lang="scss">
a {
  color: #e41722;
}
.purchase {
  border: 1px;
  border-style: solid;
  border-color: #000000;
}
</style>
